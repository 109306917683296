@font-face {
  font-family: "Sparkassesymbol rg";
  src: url("https://assets.website-files.com/5fc62dbf59bbf327d5c4a13a/5fcdf162a80b967c7b08b4b9_SparkasseSymbol_Rg.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sparkasse bd";
  src: url("https://assets.website-files.com/5fc62dbf59bbf327d5c4a13a/5fcdf1628824316fc6818ecd_Sparkasse_Bd.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sparkasse bdit";
  src: url("https://assets.website-files.com/5fc62dbf59bbf327d5c4a13a/5fcdf1624ee75bcb60c52b72_Sparkasse_BdIt.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sparkasse it";
  src: url("https://assets.website-files.com/5fc62dbf59bbf327d5c4a13a/5fcdf1623b887f5d0564b44d_Sparkasse_It.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sparkassehead rg";
  src: url("https://assets.website-files.com/5fc62dbf59bbf327d5c4a13a/5fcdf162a5d5880f4eee9ede_SparkasseHead_Rg.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sparkasse rg";
  src: url("https://assets.website-files.com/5fc62dbf59bbf327d5c4a13a/5fcdf1626f4a0a795a5162c5_Sparkasse_Rg.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
